<!--
 * @Description: 上传文件
 * @Author: xiawenlong
 * @Date: 2020-12-19 14:23:46
 * @LastEditors: xiawenlong
 * @LastEditTime: 2021-07-01 15:09:01
-->
<template>
  <div class="upload-file">
    <el-upload
      class="uploader-file"
      action=""
      :file-list="fileList"
      drag
      multiple
      :http-request="uploadRequest"
      :on-remove="handleRemove"
      :on-preview="handlePreview"
      :accept="fileType"
    >
      <i class="el-icon-upload"></i>
      <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
      <p class="tip" style="line-height:6px">支持扩展名：{{ fileType }}</p>
    </el-upload>
  </div>
</template>
<script>
import { getFileUpload } from '@/api/college'
import to from 'await-to'
export default {
  name: 'UploadImage',
  model: {
    prop: 'value',
    event: 'setModelVal',
  },
  props: {
    value: {
      type: Array,
      default: () => {},
    },
    isOneFile: {
      type: Boolean,
      default: false,
    },
    fileType: {
      type: String,
      default: '.rar,.zip,.doc,.docx,.pdf,.jpg,.jpeg',
    },
  },
  data() {
    return {
      fileList: [],
    }
  },
  watch: {
    value(val) {
      if (val.length) {
        this.fileList = val.map(item => ({ ...item, name: item.docName }))
      }
    },
  },
  methods: {
    async uploadRequest(file) {
      const params = new FormData()
      params.append('fileTypeCode', 'F002')
      params.append('file', file.file)
      params.append('fileName', file.file.name)
      const [res, err] = await to(getFileUpload(params))
      if (err) {
        this.fileList = []
        return this.$message.error(err.msg)
      }
      if (this.fileList.length > 0 && this.isOneFile == true) {
        this.$emit('setModelVal', [
          {
            name: file.file.name,
            docName: file.file.name,
            url: res.data,
          },
        ])
      } else {
        this.$emit('setModelVal', [
          ...this.fileList,
          {
            name: file.file.name,
            docName: file.file.name,
            url: res.data,
          },
        ])
      }

      console.log(this.fileList)
    },
    async handlePreview(file) {
      const response = await fetch(file.url || file.enclosureUrl)
      const blob = await response.blob()
      let url = window.URL.createObjectURL(blob)
      let a = document.createElement('a')
      a.href = url
      a.download = file.docName || file.fileName
      a.click()
      window.URL.revokeObjectURL(url)
    },
    handleRemove(file, fileList) {
      this.$emit('setModelVal', [])
      this.fileList = fileList
    },
  },
}
</script>
<style lang="scss" scoped>
.upload-file {
  ::v-deep.el-upload-list {
    width: 360px;
  }
}
</style>
